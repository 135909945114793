import dynamic from 'next/dynamic';

export default {
    ArticlePage: dynamic(() => import('./ArticlePage')),
    BasePage: dynamic(() => import('./BasePage')),
    CategoryPage: dynamic(() => import('./CategoryPage')),
    CollectionIndexPage: dynamic(() => import('./CollectionIndexPage')),
    CollectionPage: dynamic(() => import('./CollectionPage')),
    HomePage: dynamic(() => import('./HomePage')),
    NotFoundPage: dynamic(() => import('./NotFoundPage')),
    PasswordProtectedPage: dynamic(() => import('./PasswordProtectedPage')),
    PureHtmlPage: dynamic(() => import('./PureHtmlPage')),
    ResourceIndexPage: dynamic(() => import('./ResourceIndexPage')),
    ResourceItemPage: dynamic(() => import('./ResourceItemPage')),
    SectionPage: dynamic(() => import('./SectionPage')),
    PayItForwardPage: dynamic(() => import('./PayItForwardPage')), 
    EssentialsPage: dynamic(() => import('./EssentialsPage')),
};
